<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <Icon name="Send"></Icon>
        بررسی نهایی و پرداخت تبلیغ هدفمند
      </h2>
      <ul class="list-disc list-inside mt-3">
        <li class="text-red-500">
          ثبت سفارش تبلیغات به معنی قبول شرایط و
          <router-link to="/" class="text-sky-600">قوانین سفارش</router-link>
          می‌باشد
        </li>
        <li>
          برای خواندن شرایت و نحوه تبلیغات
          <router-link to="/" class="text-sky-600">اینجا کلیک کنید</router-link>
        </li>
        <li>بعد از تکمیل سفارش و پرداخت میتوانید بنر و کلید ها را مشخص کنید</li>
        <li>
          در زیر می‌توانید لیست گروه ها و ساعت هایی که انتخاب کرده اید را مشاهده کنید و
          مواردی که نمی‌خواهید رو حذف کنید
        </li>
      </ul>
    </template>
    <template #default>
      <div
        class="
          w-full
          md:w-8/12
          lg:w-6/12
          xl:w-5/12
          mx-auto
          bg-blueGray-50
          border border-coolGray-200
          rounded-md
          overflow-hidden
        "
      >
        <h2 class="text-lg font-bold text-center p-3 bg-sky-500 text-sky-50">
          سفارش : {{ pay.id }}
        </h2>
        <div>
          <div class="p-2 font-bold text-center odd:bg-blueGray-100">
            {{ pay.description }}
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">موضوع</div>
            <div class="font-bold text-left">{{ ad.type?.name ?? "سایر موارد" }}</div>
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">تعداد ارسال</div>
            <div class="font-bold text-left">{{ ad.sends?.length }}</div>
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">تعداد گروه</div>
            <div class="font-bold text-left">
              {{ Object.values(L_.groupBy(ad.sends, "group_id")).length }}
            </div>
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">کلید زیر بنر</div>
            <div class="font-bold text-left">{{ ad.sub_keys?.length ?? 0 }}</div>
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">شروع</div>
            <div class="font-bold text-left">{{ ad.start_at?.fa }}</div>
          </div>
          <div class="p-2 grid grid-cols-2 gap-1 odd:bg-blueGray-100">
            <div class="">پایان</div>
            <div class="font-bold text-left">{{ ad.end_at?.fa }}</div>
          </div>
          <div class="p-2 text-center font-bold text-xl bg-sky-500 text-sky-50">
            {{ $number.format(pay.amount) }} تومان
          </div>
        </div>
      </div>
    </template>
    <template #bottom>
      <div class="flex w-full">
        <div class="flex-1 mx-1">
          <Button
            @click="payByCharge(false)"
            v-if="$store.state.user?.admin?.is_admin"
            class="text-sm h-10 lg:h-12 lg:text-lg"
            color="green"
            :disabled="disabaledForm"
          >
            پرداخت {{ $number.format(pay.amount) }} تومان (ادمین)
          </Button>
          <Button
            v-else
            :href="apiUrl + 'api/go-pay/' + pay.token"
            class="text-sm h-10 lg:h-12 lg:text-lg"
            color="green"
          >
            پرداخت {{ $number.format(pay.amount) }} تومان
          </Button>
        </div>
        <div
          class="flex-1 mx-1"
          v-if="
            pay.amount <=
            $store.state.user.wallet +
              $store.state.user.earn +
              $store.state.user.bonus -
              $store.state.user.debit
          "
        >
          <Button
            @click="payByCharge"
            class="text-sm h-10 lg:h-12 lg:text-lg"
            color="teal"
            :disabled="disabaledForm"
          >
            پرداخت از شارژ
          </Button>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";
import _ from "lodash";

export default {
  components: {
    Icon,
    Button,
    BodySimple,
  },
  data() {
    return {
      sends: [],
      ad: {},
      L_: _,
      firstLoading: true,
      disabaledForm: false,
      pay: {},
      apiUrl: process.env.VUE_APP_PAY_URL,
    };
  },
  mounted() {
    let $this = this;

    this.$router
      .isReady()
      .then(function () {
        $this.getAd();
      })
      .catch();
  },
  methods: {
    getAd() {
      let $this = this;
      $this.$axios
        .get("/api/ad-target-pays/" + $this.$route.params.payId, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          $this.pay = response.data.pay;
          $this.ad = response.data.ad;
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
          $this.disabaledForm = false;
        });
    },
    payByCharge(charge = true) {
      // console.log("p b a");
      let $this = this;
      $this.errors = {};
      $this.disabaledForm = true;
      $this.$axios
        .put("/api/ad-target-pays/" + $this.$route.params.payId, {
          id: $this.$store.state.user.id,
          charge: charge,
        })
        .then(function (response) {
          // console.log(response);
          $this.$router.push(response.data.goTo);
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.disabaledForm = false;
        });
    },
  },
};
</script>
